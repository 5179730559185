import React from "react";
import '../App.css';
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";



function Legal() {
    return (
        <>
         <Helmet>
            <title>Legal | McKays Mill HOA</title>
            <meta name="description" content="McKays Mill HOA" />
        </Helmet>
        {/* Favicon*/}
        <link rel="icon" type="image/x-icon" href="assets/favicon.ico" />
        {/* Core theme CSS (includes Bootstrap)*/}
        <link href="css/styles.css" rel="stylesheet" />
        {/* Responsive navbar*/}
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container">
            <a className="navbar-brand text-center text-uppercase fw-bolder" href="#">
              MCKAYSMILL-HOA.COM
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a
                    className="nav-link active text-uppercase fw-bolder"
                    aria-current="page"
                    href="https://mckaysmill-hoa.com"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active text-uppercase fw-bolder"
                    href="https://stateoftennessee.formstack.com/forms/dca"
                    target="_blank"
                  >
                    Report
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active text-uppercase fw-bolder"
                    href="petition"
                  >
                    Petition
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link active text-uppercase fw-bolder"
                    href="legal"
                  >
                    Legal
                  </a>
                </li>
                <li className="nav-item dropdown"></li>
              </ul>
            </div>
          </div>
        </nav>
        {/* Page content*/}
        <div className="container">
          <div className="text-center mt-5">
            <p className="lead text-uppercase fw-bold pt-4">
              MCKAYS MILL HOA- UNETHICAL, UNPROFESSIONAL AND RUDE
            </p>
            <p className="lead text-uppercase">
              1215 Habersham Way, Franklin, TN 37067
            </p>
          </div>
        </div>
        <div className="container py-5">
          <p>
            The information provided on this website is factual, documented and there
            is considerable credible evidence against the McKays Mill HOA committee
            and its members. These agressive actions and behavior must stop. File now
            to help your community hold these individuals accountable.
          </p>
          <p>
            Want to help by signing our community petition? Read more here{" "}
            <a href="https://mckaysmill-hoa.com/petition">Click Here</a>
          </p>
        </div>
        <div className="container text-center d-grid  mx-auto m-4">
          <a
            href="https://stateoftennessee.formstack.com/forms/dca"
            target="blank"
            className="btn btn-primary active"
            type="button"
            id="cc"
            style={{ backgroundColor: "#0377fc !important" }}
          >
            SUPPORT US BY FILING A REPORT
          </a>
        </div>
        {/* Bootstrap core JS*/}
        {/* Core theme JS*/}
      </>
      
        
        );
    }
    
    export default Legal;