import React from "react";
import '../App.css';
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";



function Home() {
    return (
        <>
  <Helmet>
            <title>Home | McKays Mill HOA</title>
            <meta name="description" content="McKays Mill HOA" />
  </Helmet>
  {/* Favicon*/}
  <link rel="icon" type="image/x-icon" href="assets/favicon.ico" />
  {/* Core theme CSS (includes Bootstrap)*/}
  <link href="css/styles.css" rel="stylesheet" />
  {/* Responsive navbar*/}
  <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
    <div className="container">
      <a className="navbar-brand text-center text-uppercase fw-bolder" href="">
        MCKAYSMILL-HOA.COM
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a
              className="nav-link active text-uppercase fw-bolder"
              aria-current="page"
              
            >
              <Link to="/">Home</Link>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link active text-uppercase fw-bolder"
              href="https://stateoftennessee.formstack.com/forms/dca"
              target="_blank"
            >
              Report
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link active text-uppercase fw-bolder"
              href="petition"
            >
              <Link to="/Petition">Petition</Link>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link active text-uppercase fw-bolder"
             
            >
              <Link to="/Legal">Legal</Link>
            </a>
          </li>
          <li className="nav-item dropdown"></li>
        </ul>
      </div>
    </div>
  </nav>
  {/* Page content*/}
  <div className="container">
    <div className="text-center mt-5">
      <p className="lead text-uppercase fw-bold pt-4">
        MCKAYS MILL HOA- UNETHICAL, UNPROFESSIONAL AND RUDE
      </p>
      <p className="lead text-uppercase">
        1215 Habersham Way, Franklin, TN 37067
      </p>
    </div>
  </div>
  <div className="container">
    <div className="text-left mt-5 py-5">
      <p>
        The HOA body supporting this community is aggressive and dangerous. A
        new board that was recently appointed changed an entire set of bylaws
        into laws essentially misleading the community, throwing the bylaw
        changes in the bottom of a set of due documents. These documents were
        sent purposefully and done with intent at the end of
        2021. This HOA board has a network of individuals who work to watch and
        oversee the community. This even spans to certain individuals who live
        in my proximity. If you don’t like or don’t support something the board
        does, chances are you might become a target by these individuals, which
        is what has happened to me and my family. It is time to hold the board
        and all of its members accountable. Our Change.org petition now has over one hundred signatures thanks to our
        incredible community members standing strong. Click below to sign and support your community!  
      </p>
      <div className="container text-center d-grid  mx-auto m-4">
        <a
          href="https://www.change.org/p/mckays-mill-hoa-committee-petition"
          target="blank"
          className="btn btn-primary active"
          type="button"
          id="cc"
          style={{ backgroundColor: "#0377fc !important" }}
        >
          <b>SIGN CHANGE.ORG PETITION</b>
        </a>
      </div>
      
      <p>
        The HOA Committee pays Kristy Bowman, a CMA Community
        Management who oversees our community here in franklin, TN.{" "}
        <a
          href="https://cmacommunities.com/properties/mckays-mill-master-assn/"
          target="_blank"
        >
          Kristy Bowman
        </a>{" "}
        has purposely ignored my communications and unethically lied about
        situations to law enforcement and others to again betterment her
        position to assist the board in its agenda. After communicating with a patrol
        deputy on the issue it was obvious how Kristy Bowman misled the deputy about the situation. This is a perfect
        example though if you question these members they will more than likely
        target you. I tried to make contact through phone and email to
        which again these communications were again fully ignored by {" "}
        <a
          href="https://cmacommunities.com/properties/mckays-mill-master-assn/"
          target="_blank"
        >
          Kristy Bowman
        </a>{" "}
        and the committee. I then reached out to a manager above her, Bill Chance at CMA trying
        to figure out why I was being ignored only to receive an aggressive phone call back
        stating I would be trespassed from our community clubhouse for
        harassment. When I asked Bill Chance about how I caused the
        harassment, he stumbled to find an excuse and stated I was quote 'banging
        on the windows' at our community clubhouse which is completely false. These
        allegations should be noted and are being used again to target me. The
        Committee past and present keeps legal issues as quiet as possible given
        members have in fact, based on public record, have had similar issues
        within the community. Using {" "}
        <a
          href="https://cmacommunities.com/properties/mckays-mill-master-assn/"
          target="_blank"
        >
          Kristy Bowman
        </a>{" "}
        as legal assurance these committee members push the boundaries of
        general professionalism, community standards and Tennessee law. Supporting these actions is the president of the HOA, Mike Franklin. Mike Franklin has allowed for
        unlawful police reporting to take place and he continues to allow the Community Manager, Kristy Bowman to act through her egregious
        methods using attorneys and other threatning tactics.
      </p>
      <p>
        {" "}
        
        <a
          href="https://cmacommunities.com/properties/mckays-mill-master-assn/"
          target="_blank"
        >
          Kristy Bowman
        </a>{" "}
        does not have any online images of herself and when you speak to her,
        her body language says everything you need to know. This manager is
        incredibly nervous and uneasy but why is this? The first time I ever spoke to Mrs. Bowman at a board meeting she walked away and wouldn't
        even speak to me. Several minutes later she would return nervous even to shake my hand in the lobby, but why though? CMA Community Management 
        has a set of lawyers protecting these individuals and when they don't like something they press legal action given its an easy out to 
        get away from issues they have more than likely caused. Here's a recent review on CMAs{" "}
        <a
          href="https://www.google.com/search?q=cma+community+management+ga&rlz=1C1VDKB_enUS1018US1018&ei=KalaY6KLCOiOwbkPo9-eWA&ved=0ahUKEwjiw7_94YD7AhVoRzABHaOvBwsQ4dUDCBA&uact=5&oq=cma+community+management+ga&gs_lcp=Cgdnd3Mtd2l6EAMyBggAEBYQHjICCCYyBQgAEIYDMgUIABCGAzIFCAAQhgMyBQgAEIYDOgoIABBHENYEELADOgQIABBDOgUIABCABDoQCC4QsQMQgwEQxwEQ0QMQQzoKCAAQsQMQgwEQQzoNCC4QsQMQxwEQrwEQQzoHCAAQsQMQQzoFCAAQkQI6DgguEIAEELEDEMcBEK8BOgsIABCABBCxAxCDAToLCC4QgAQQxwEQrwFKBAhBGABKBAhGGABQlwdYmiZgyiZoAXABeACAAZMCiAH5G5IBBjAuMTkuM5gBAKABAcgBCMABAQ&sclient=gws-wiz#lrd=0x88f504e53739c1fd:0x347290c16faf8add,1,,,&topic=mid:/m/01pmzd"
          target="_blank"
        >
          Google Reviews
        </a>{" "}
        page that explains a very similar situation: 'Kimberly Mitchell, The CMA
        property manager from my neighborhood (Bentwater) called my wife and
        harassed, threatened her with court and to get a lawyer and said the ARC
        reviewer will deny my request. She then covered up the truth about other
        homes but selectively enfoced mine. The truth was exposed at an appeals
        meeting but thr HOA protected her with no consequence. The covenants do
        not say that type of behavior is what happens to homeowners. She broke
        the law and violated the covenants. My formal complaint letter went
        unanswered and ignored. CMA business blamed me instead. Avoid this
        embarrassing business.' To no suprise CMA Community Management
        Associates also has a 2.0/5.0 as a rating on Google and once you start
        reading some of the reviews you'll start to question should we have
        someone from this business operating our community? On Yelp they have a
        1.0/5.0 and this provides a very good indicator we should not be
        represented by individuals associated with CMA.
      </p>
      <p>
        CC&amp;R changes are fairly common in communities. CC&amp;R changes for
        the McKays Mill Community occured on 10/05/21 changing approval
        procedures, vehicle parking, tree planting and more. In fact there were
        a total of ten rule adjustments in that one ammendment document alone.
        The Master Board did not properly communicate these ammendments, the
        Master Board committee submitted these ammendments in the same packet
        discussing annual dues and at the end of the document(s) home owners
        were informed by submitting and agreeing to those dues that they would
        essentially be agreeing to the bylaw ammendment changes attached in that letter. 
        You can see the ammendment changes below. Signatures were required but the document was
        falsely presented to homeowners as it was presented at the very end of
        the due documents. Legally the Master Board should have informed home
        owners of these changes in a seperate letter notifying them of these
        ammendments. If these ammendments were important than why were they not
        placed in the very front of the packet? Why were they placed at the end?
        When I asked this question at the board meeting I was told they would
        'need that in writing.' 
        <a
          href="https://mckaysmillhoa.com/wp-content/uploads/2022/01/Ten-Rules-and-Regulations-October-2021.pdf"
          target="_blank"
        >
           CC&amp;R Rule and Regulation Changes
        </a>
      </p>
      <p>
        CMA does not care about those who they effect they simply care about
        those who employee their employees helping them to stay in business. The
        HOA committee and its members pay a general salary to the community
        manger and she again acts as directed to cover up issues almost exactly
        like the described above. Bill Chance, the CMA Regional Manager as mentioned again would
        call stating the false accusations that I had harassed Kristy Bowman
        and that I was banned from the clubhouse. On that phone call he
        would hang up on me and after leaving a voicemail he would never return
        any of my other calls. All claims in relation to any harassment again are
        completely false and I have released bodycam footage of the incident as
        shown in the video here{" "}
        <a href="https://youtu.be/BlUiqw251ws" target="_blank">
          Click Here
        </a>
        . This footage has been released to document the false allegations put
        against me, this is a perfect example yet again how the HOA targets
        individuals it does not like. This situation has been reported to the
        Williamson County Sheriff's Office and several other resources including
        News Channel 4 and 5 for further investigation.
      </p>
      <p>
        With the McKays Mill HOA Committee you will never hear from a board
        member, just Kristy Bowman. Members leverage this
        position as a shield while they make rulings and adjustments to the
        community and when asked questions there's no response. I have always
        been very professional in communicating about issues in the community so
        why would they ignore me and then provide false reports to law
        enforcement? The actions by Kristy Bowman and Bill Chance further validate 
        my claims as to the behavior of the HOA
        committee and how they perform through their egregious acts. Months
        prior to this situation I tried communicating with the board on two other issues
        with our home and in return they sent aggressive short responses not
        working to resolve the issues we were facing but they would ultimately
        end up creating additional issues for me and my family. One of those
        issues costing us thousands.{" "}
      </p>
      <p>
        If you have had an issue with the McKays Mill HOA support your community
        and others by filing with the state attorney by clicking below. These
        abusive actions must stop and the HOA committee and those who manage it
        need to be held accountable for their actions and behavior, let your
        representatives know.
      </p>
      <div className="container text-center d-grid  mx-auto m-4">
        <a
          href="https://www.change.org/p/mckays-mill-hoa-committee-petition"
          target="blank"
          className="btn btn-primary active"
          type="button"
          id="cc"
          style={{ backgroundColor: "#0377fc !important" }}
        >
          <b>SIGN CHANGE.ORG PETITION</b>
        </a>
      </div>
      
      <div className="container text-center d-grid  mx-auto m-4">
        <a
          href="https://stateoftennessee.formstack.com/forms/dca"
          target="blank"
          className="btn btn-primary active"
          type="button"
          id="cc"
          style={{ backgroundColor: "#0377fc !important" }}
        >
          <b>FILE STATE ATTORNEY REPORT</b>
        </a>
      </div>
    </div>
  </div>
  <div className="container p-4" />
  {/* Bootstrap core JS*/}
  {/* Core theme JS*/}
</>

        
        );
    }
    
    export default Home;
    